import styled from "styled-components"

export const Header = styled.header`
  /*background-color: rgba(0, 0, 0, 0.04);
  min-height: 635px;*/
`

export const HeaderTitle = styled.h1`
  font-weight: 700;
  text-rendering: optimizeLegibility;
  font-size: 3.5rem;
  line-height: 1.1;
  margin-top: 100px;
`
