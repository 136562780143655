import { CSSTransition, TransitionGroup } from "react-transition-group"
import { Content, GlobalStyle } from "../components/Layout"
import { FiMail, FiPhone } from "react-icons/fi"
import { Header, HeaderTitle } from "../components/Header"

import { Footer } from "../components/Footer"
import { Questions } from "../components/Questions"
import React from "react"
import { Separator } from "../components/Separator"
import Typed from "react-typed"
import styled from "styled-components"

const Paragraph = styled.p`
  font-size: 1.5rem;
`

const Contact = styled.p`
  font-size: 1rem;
  display: flex;
  align-items: center;
`

const ContactIcon = styled.span`
  margin-right: 10px;
`

const VerbosityToggleInstructions = styled.p`
  margin: 0;
`

const VerbosityToggleContainer = styled.div`
  top: 0;
  position: sticky;
  background-color: white;
  z-index: 1;
  padding-top: 1em;
  padding-bottom: 1em;
`

const VerbosityToggle = styled.div`
  padding: 0.75rem 0.5rem 0.75rem 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

const VerbosityToggleInner = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 750px) {
    align-items: normal;
    flex-direction: column;
  }
`

const VerbosityButtons = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  @media (max-width: 750px) {
    justify-content: start;
  }
`

const VerbosityButton = styled.button`
  background: ${props =>
    props.active
      ? "linear-gradient(180deg, rgb(235,235,235) 0,rgb(245,245,245));"
      : "rgb(255, 255, 255)"};
  padding: 10px;
  margin: 0 0px;
  border: 1px solid rgba(55, 68, 76, 0.4);
  border-radius: 7px;
  color: rgba(55, 68, 76, 0.8);
  min-width: 100px;
  cursor: pointer;

  &:hover {
    background-color: rgba(250, 250, 250);
  }
`

const TerseButton = styled(VerbosityButton)`
  border-radius: 7px 0 0 7px;
  border-right: none;
`

const VerboseButton = styled(VerbosityButton)`
  border-radius: 0 7px 7px 0;
`

const CloseVerbosityButton = styled.button`
  background-color: transparent;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  flex-grow: 0;
`

export default function Home() {
  const [showVerbosityToggle, setShowVerbosityToggle] = React.useState(true)
  const [verbosity, setVerbosity] = React.useState("verbose")

  const [isMounted, setIsMounted] = React.useState(false)

  React.useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 1)
    return () => clearTimeout(timeout)
  }, [])

  const headerElements = [
    <Paragraph style={{ transitionDelay: "1000ms" }}>Hi, I'm Tim.</Paragraph>,
    <Paragraph style={{ transitionDelay: "1050ms" }}>
      Thanks for considering my application for a{" "}
      <strong>Senior Web Full Stack Engineer</strong> position in London, UK.
    </Paragraph>,
    <Paragraph style={{ transitionDelay: "1100ms" }}>
      Click{" "}
      <a
        rel="noopener noreferrer"
        target="_blank"
        title="CV"
        href="./TimPhillips-Expensify-CV.pdf"
      >
        here
      </a>{" "}
      to view a traditional CV detailing my past experience and qualifications.
    </Paragraph>,
    <Paragraph style={{ transitionDelay: "1150ms" }}>
      My responses to the application questions are below.
    </Paragraph>,
    <Separator style={{ transitionDelay: "1150ms", marginTop: 70 }} />,
  ]

  return (
    <>
      <GlobalStyle />
      <Header>
        <Content>
          <Typed
            showCursor={false}
            strings={["Dear Expensify,"]}
            typeSpeed={40}
          >
            <HeaderTitle />
          </Typed>
          <TransitionGroup>
            {isMounted &&
              headerElements.map((item, i) => (
                <CSSTransition key={i} classNames="fade" timeout={500}>
                  {item}
                </CSSTransition>
              ))}
          </TransitionGroup>
        </Content>
      </Header>
      <TransitionGroup>
        {isMounted && (
          <CSSTransition classNames="fade" timeout={500}>
            <Content style={{ transitionDelay: "1600ms" }}>
              {showVerbosityToggle && (
                <VerbosityToggleContainer>
                  <VerbosityToggle>
                    <VerbosityToggleInner>
                      <VerbosityToggleInstructions>
                        Feel free to adjust the verbosity of the responses
                      </VerbosityToggleInstructions>
                      <VerbosityButtons>
                        <TerseButton
                          title="Just the key facts"
                          active={verbosity === "terse"}
                          onClick={() => setVerbosity("terse")}
                        >
                          Terse
                        </TerseButton>
                        <VerboseButton
                          title="With a bit more detail and introspection"
                          active={verbosity === "verbose"}
                          onClick={() => setVerbosity("verbose")}
                        >
                          Verbose
                        </VerboseButton>
                      </VerbosityButtons>
                    </VerbosityToggleInner>
                    <CloseVerbosityButton
                      onClick={() => setShowVerbosityToggle(false)}
                    >
                      ×
                    </CloseVerbosityButton>
                  </VerbosityToggle>
                </VerbosityToggleContainer>
              )}
              <Questions verbosity={verbosity} />
            </Content>
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {isMounted && (
          <CSSTransition classNames="fade" timeout={500}>
            <Footer style={{ transitionDelay: "2200ms" }}>
              <Content>
                <Paragraph>Thanks again!</Paragraph>
                <Paragraph>
                  I'm looking forward to hearing from you and learning more
                  about the position. Please reach out if you have any questions
                  about my history or experience.
                </Paragraph>
                <Paragraph>
                  One thing to note is that I will require a Tier 2 visa
                  sponsorship for this position.
                </Paragraph>
                <Paragraph>Tim</Paragraph>
                <Contact>
                  <ContactIcon>
                    <FiMail />
                  </ContactIcon>
                  <a href="mailto:timdphillips@gmail.com">
                    timdphillips@gmail.com
                  </a>
                </Contact>
                <Contact>
                  <ContactIcon>
                    <FiPhone />
                  </ContactIcon>
                  <a href="tel:+44 07516 568479">+44 07516 568479</a>
                </Contact>
              </Content>
            </Footer>
          </CSSTransition>
        )}
      </TransitionGroup>
    </>
  )
}
