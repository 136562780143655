import styled from "styled-components"

export const Separator = styled.hr`
  margin-top: 30px;
  margin-bottom: 30px;
  border: 0;
  height: 1px;
  background-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    #d3dadf,
    rgba(0, 0, 0, 0)
  );
`
