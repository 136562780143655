import React from "react"
import styled from "styled-components"

const StyledTable = styled.table`
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
`

const StyledTableFooter = styled.tfoot`
  font-size: 0.7em;
  margin-top: 20px;
`

const StyledDataCellValue = styled.td`
  text-align: right;
  font-weight: 600;
  min-width: 150px;
`

const StyledCaption = styled.caption`
  margin-bottom: 20px;
  font-weight: 700;
`

const StyledDataCellLabel = styled.td``

export const ContributionStats = () => {
  const stats = [
    {
      label: "Commits",
      value: "8,118",
    },
    {
      label: "Pull Requests Opened",
      value: "772",
    },
    {
      label: "Pull Requests Reviewed *",
      value: "1,071",
    },
    {
      label: "Pull Request Comments",
      value: "19,929",
    },
    {
      label: "Repositories Contributed To",
      value: "13",
    },
    {
      label: "Slack Messages Sent",
      value: "33,337",
    },
  ]
  return (
    <StyledTable>
      <StyledCaption>7 Years At Pandell, By The Numbers</StyledCaption>
      <StyledTableFooter>
        <tr>
          <td>* Since GitHub introduced reviews in 2017</td>
        </tr>
      </StyledTableFooter>
      <tbody>
        {stats.map(stat => (
          <tr key={stat.label}>
            <StyledDataCellLabel>{stat.label}</StyledDataCellLabel>
            <StyledDataCellValue>{stat.value}</StyledDataCellValue>
          </tr>
        ))}
      </tbody>
    </StyledTable>
  )
}
