import styled, { createGlobalStyle } from "styled-components"

import TransitionStyles from "../styles/TransitionStyles"

export const GlobalStyle = createGlobalStyle`
  body {
    color: #37444c;
    margin: 0;
    font-weight: 200;
    line-height: 2rem;
    font: 100%/1.666 'Open Sans',sans-serif;
}

  }
  ol {
    padding: 0;
    margin: 0;
  }
  a,
  a:visited,
  a:active {
    color: inherit;
  }

  #SRLLightbox {
    z-index: 2;
  }

  ${TransitionStyles}
`

export const Content = styled.div`
  max-width: 700px;
  margin: 0px auto;
  padding: 2rem 2rem 0 2rem;
`
