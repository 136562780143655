import SimpleReactLightbox, {
  SRLWrapper,
  useLightbox,
} from "simple-react-lightbox"

import CapitalOverhead from "../assets/Capital-Overhead.png"
import CapitalOverheadThumbnail from "../assets/Capital-Overhead.png"
import { ContributionStats } from "./ContributionStats"
import CoreDevelopers from "../assets/Core-Developers.png"
import DashboardGrid from "../assets/Dashboard-Grid.png"
import DashboardGridThumbnail from "../assets/Dashboard-Grid-Thumbnail.png"
import DashboardMap from "../assets/Dashboard-Map.png"
import DashboardMapThumbnail from "../assets/Dashboard-Map-Thumbnail.png"
import DashboardWidgets from "../assets/Dashboard-Widgets.png"
import DashboardWidgetsThumbnail from "../assets/Dashboard-Widgets-Thumbnail.png"
import { FaCircle } from "react-icons/fa"
import GameScreenshot1 from "../assets/Old-Game.jpg"
import GameScreenshot2 from "../assets/Old-Game-2.jpg"
import InspectorPanel from "../assets/Inspector-Panel.png"
import InspectorPanelThumbnail from "../assets/Inspector-Panel-Thumbnail.png"
import InspectorPanelUsage from "../assets/Inspector-Panel-Usage.png"
import InspectorPanelUsageThumbnail from "../assets/Inspector-Panel-Usage-Thumbnail.png"
import Lighthouse from "../assets/Lighthouse.png"
import MonthEndChecklist from "../assets/Month-End-Checklist.png"
import MonthEndChecklistThumbnail from "../assets/Month-End-Checklist-Thumbnail.png"
import React from "react"
import RefreshFrequency from "../assets/Refresh-Frequency.png"
import RefreshFrequencyThumbnail from "../assets/Refresh-Frequency-Thumbnail.png"
import { Separator } from "./Separator"
import SummaryChangesGrid from "../assets/Summary-Changes.png"
import SummaryChangesGridThumbnail from "../assets/Summary-Changes-Thumbnail.png"
import VoucherDataEntry from "../assets/Voucher-Data-Entry.png"
import VoucherDataEntryThumbnail from "../assets/Voucher-Data-Entry-Thumbnail.png"
import styled from "styled-components"

const QuestionList = styled.ol`
  margin-top: 50px;
  list-style: none;
`

const QuestionTitle = styled.h2`
  color: #566a76;
  font-weight: 400;
  font-size: 1.3rem;
`
const P = styled.p``

const Italic = styled.span`
  font-style: italic;
`
const Bold = styled.span`
  font-weight: bold;
`

const Quote = styled.p`
  font-style: italic;
  color: #566a76;
  padding-left: 30px;
  padding-right: 30px;
`

const Highlight = styled.p`
  font-weight: 700;
`

const TimeLineTitle = styled(Highlight)`
  margin-top: 0;
`

const Question = styled.li`
  position: relative;
  margin: 0;
  padding: 0;
  margin-bottom: 5rem;

  &:before {
    content: "";
    width: 3rem;
    height: 1px;
    background-color: #566a76;
    display: inline-block;
    position: absolute;
    top: 1rem;
    left: -80px;
  }
`

const TimeLine = styled.ol`
  margin-left: 5px;
  margin-bottom: 50px;
`

const TimeLineEntry = styled.li`
  display: flex;
  margin-top: 20px;
`

const TimeLineIcon = styled(FaCircle)`
  margin-top: 4px;
  color: #d3dadf;
`

const TimeLineSideBar = styled.div`
  position: relative;
  width: 20px;

  &::before {
    position: absolute;
    top: 4px;
    width: 2px;
    background: #d3dadf;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    content: "";
    bottom: -24px;
  }
`

const TimeLineList = styled.ul`
  margin-bottom: 20px;
`

const TimeLineContent = styled.div`
  margin-left: 20px;
`

const LightBoxThumbnailsList = styled.ul`
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  padding: 0;
  align-items: center;

  @media (max-width: 750px) {
    flex-direction: column;
  }
`
const LightBoxThumbnailsListItem = styled.li``

const LightBoxThumbnailButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
`

const LightBoxThumbnail = styled.img`
  max-height: 120px;
`

const LightBoxThumbnails = ({ images }) => {
  const { openLightbox } = useLightbox()
  return (
    <LightBoxThumbnailsList>
      {images.map((image, index) => (
        <LightBoxThumbnailsListItem key={index}>
          <LightBoxThumbnailButton
            onClick={() => openLightbox(index)}
            aria-label={image.thumbnail}
          >
            <LightBoxThumbnail src={image.thumbnail} alt={image.thumbnail} />
          </LightBoxThumbnailButton>
        </LightBoxThumbnailsListItem>
      ))}
    </LightBoxThumbnailsList>
  )
}

const LightBox = ({ images }) => {
  return (
    <SimpleReactLightbox>
      <LightBoxThumbnails images={images} />
      <SRLWrapper
        options={{
          settings: {
            overlayColor: "rgba(100, 100,100, 0.9)",
          },
          buttons: {
            showDownloadButton: false,
            showAutoplayButton: false,
            showThumbnailsButton: false,
            showFullscreenButton: false,
          },
          thumbnails: {
            showThumbnails: images.length > 1,
          },
        }}
        images={images}
      />
    </SimpleReactLightbox>
  )
}

const LightBoxLink = ({ children }) => {
  const { openLightbox } = useLightbox()
  return (
    <a
      href="#"
      onClick={e => {
        openLightbox(0)
        e.preventDefault()
      }}
    >
      {children}
    </a>
  )
}

const LightBoxText = ({ images, children }) => {
  return (
    <SimpleReactLightbox>
      {children}
      <SRLWrapper
        options={{
          settings: {
            overlayColor: "rgba(100, 100,100, 0.9)",
          },
          buttons: {
            showDownloadButton: false,
            showAutoplayButton: false,
            showThumbnailsButton: false,
            showFullscreenButton: false,
          },
          thumbnails: {
            showThumbnails: images.length > 1,
          },
        }}
        images={images}
      />
    </SimpleReactLightbox>
  )
}

export const Questions = ({ verbosity }) => {
  let website
  switch (verbosity) {
    case "terse": {
      website = (
        <LightBoxText
          images={[
            {
              src: Lighthouse,
              thumbnail: Lighthouse,
              caption: "tim-phillips.com Lighthouse Report",
            },
          ]}
        >
          <P>
            My personal website is{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Tim Phillips"
              href="https://www.tim-phillips.com"
            >
              tim-phillips.com
            </a>
            . I have a few small coding projects and creative experiments to
            look through, along with some of my favourite travel photos. The
            site is{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="tim-phillips.com on GitHub"
              href="https://github.com/timphillips/tim-phillips.com"
            >
              open source
            </a>{" "}
            and achieves a <LightBoxLink>near-perfect score</LightBoxLink> on
            Google Chrome's Lighthouse Audit.
          </P>
        </LightBoxText>
      )
      break
    }
    case "verbose": {
      website = (
        <>
          <P>
            My personal website is{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Tim Phillips"
              href="https://www.tim-phillips.com"
            >
              tim-phillips.com
            </a>
            . I have a few small coding projects and creative experiments to
            look through, along with some of my favourite travel photos.
          </P>
          <P>
            I rebuild my personal website every year or so — usually to learn
            about a new technology or framework. The latest version is built
            with{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Gatsby.js"
              href="https://www.gatsbyjs.org/"
            >
              Gatsby
            </a>
            , a React-based framework for building highly performant static
            sites. The site is{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Gatsby Site Showcase"
              href="https://www.gatsbyjs.org/showcase/www.tim-phillips.com"
            >
              featured
            </a>{" "}
            in Gatsby's official site showcase.
          </P>
          <LightBoxText
            images={[
              {
                src: Lighthouse,
                thumbnail: Lighthouse,
                caption: "tim-phillips.com Lighthouse Reprot",
              },
            ]}
          >
            <P>
              The site is{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                title="tim-phillips.com on GitHub"
                href="https://github.com/timphillips/tim-phillips.com"
              >
                open source
              </a>{" "}
              and achieves a <LightBoxLink>near-perfect score</LightBoxLink> on
              Google Chrome's Lighthouse Audit.
            </P>
          </LightBoxText>
        </>
      )
      break
    }
  }

  let codingHistoryIntro
  switch (verbosity) {
    case "terse": {
      codingHistoryIntro = (
        <>
          <P>
            In summary, I have eight years of professional experience working as
            a full-stack software engineer. I've worked on web-based accounting
            products for the last seven years.
          </P>
          <P>
            A more condensed version of my experience can also be found in my{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="CV"
              href="./TimPhillips-Expensify-CV.pdf"
            >
              CV
            </a>
            .
          </P>
        </>
      )
      break
    }
    case "verbose": {
      codingHistoryIntro = (
        <>
          <P>
            In summary, I have eight years of professional experience working as
            a full-stack software engineer. I've worked on web-based accounting
            products for the last seven years, in a variety of roles of
            increasing seniority.
          </P>
          <P>
            My coding history can be broken down into a few major phases. A more
            condensed version of my experience can also be found in my{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="CV"
              href="./TimPhillips-Expensify-CV.pdf"
            >
              CV
            </a>
            .
          </P>
        </>
      )
      break
    }
  }

  let childhood
  switch (verbosity) {
    case "terse": {
      childhood = (
        <P>
          I started coding when I was a kid. When I was around 10, my dad set up
          some webspace for me and I spent hours building a Spider-Man fansite.
          As I got older, I became interested in 3D graphics and one of my
          biggest projects was designing and programming a{" "}
          <LightBoxLink>point-and-click adventure game</LightBoxLink> in 2005.
        </P>
      )
      break
    }
    case "verbose": {
      childhood = (
        <P>
          I started coding when I was a kid. My dad worked on high-performance
          computing at a university, so technology was a regular topic at our
          dinner table. When I was around 10, my dad set up some webspace for me
          and I spent hours building a Spider-Man fansite. As I got older, I
          started pursuing some real coding projects. I became interested in 3D
          graphics and one of my biggest projects was designing and programming
          a <LightBoxLink>point-and-click adventure game</LightBoxLink> in 2005.
        </P>
      )
      break
    }
  }

  let university
  switch (verbosity) {
    case "terse": {
      university = (
        <P>
          I studied computer science at the University of Alberta in Edmonton,
          Alberta. I pursued a specialization in bioinformatics{" "}
          <span role="img" aria-label="Bioinformations">
            🌿
          </span>{" "}
          and graduated with First Class Honours.
        </P>
      )
      break
    }
    case "verbose": {
      university = (
        <>
          <P>
            I went to university at the University of Alberta in Edmonton,
            Alberta. I studied computer science and graduated with First Class
            Honours.
          </P>
          <P>
            I pursued a specialization in bioinformatics{" "}
            <span role="img" aria-label="Bioinformations">
              🌿
            </span>{" "}
            because I was interested in genomics and big data. I completed a
            final project working on the{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Small Molecule Pathway Database"
              href="https://smpdb.ca/"
            >
              Small Molecule Pathway Database
            </a>{" "}
            at the{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Small Molecule Pathway Database"
              href="http://www.wishartlab.com/"
            >
              Wishart Research Group
            </a>
            . It was a rewarding experience, but over time I found I gravitated
            more towards software engineering than academic research.
          </P>
        </>
      )
      break
    }
  }

  let hamonetics
  switch (verbosity) {
    case "terse": {
      hamonetics = (
        <P>
          As part of my degree, I completed a 16-month internship at
          Haemonetics, where I worked as a full-stack developer on a Java EE web
          application. Since we were developing medical software, there were
          extremely rigorous quality standards that had to be met. This
          uncompromising attitude towards <Italic>quality</Italic> in all facets
          of development was hugely influential for me.
        </P>
      )
      break
    }
    case "verbose": {
      hamonetics = (
        <>
          <P>
            As part of my degree, I completed a 16-month internship at
            Haemonetics, a global leader in medical software. I worked as a
            full-stack developer on a Java EE web application used by blood
            donor clinics to determine donor eligibility. Looking back on it
            now, this internship was a particularly important step in my
            education. Since we were developing medical software, there were
            extremely rigorous quality standards that had to be met. If our
            software had a bug and allowed someone to donate blood who wasn't
            eligible, there could have been serious health implications.
          </P>
          <P>
            This uncompromising attitude towards <Italic>quality</Italic> in all
            facets of development was hugely influential. This mindset has
            stayed with me throughout my career and has set a higher standard
            for my own work and the teams that I work with.
          </P>
        </>
      )
      break
    }
  }

  let pandellIntro
  switch (verbosity) {
    case "terse": {
      pandellIntro = (
        <P>
          I joined{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            title="Pandell"
            href="https://www.pandell.com"
          >
            Pandell
          </a>{" "}
          in 2013. Over the last seven years, I've grown from a junior developer
          to one of principal developers at the company. I'll highlight some of
          my most significant roles below.
        </P>
      )
      break
    }
    case "verbose": {
      pandellIntro = (
        <P>
          I joined{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            title="Pandell"
            href="https://www.pandell.com"
          >
            Pandell
          </a>{" "}
          in 2013 and I've been there ever since. We're a small-to-mid sized
          Canadian software company building accounting and mapping software for
          Oil &amp; Energy companies. It has been a whirlwind experience. Over
          the last seven years, I've grown from a junior developer to one of
          principal developers at the company. I'll highlight some of my most
          significant roles below.
        </P>
      )
      break
    }
  }

  let pandellEarlyYears
  switch (verbosity) {
    case "terse": {
      pandellEarlyYears = (
        <>
          <P>
            The goal of this project was to rewrite Pandell's flagship
            accounting product,{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Pandell JV"
              href="https://www.pandell.com/energy/pandelljv/"
            >
              Pandell JV
            </a>
            , from a 15-year-old legacy desktop application to a modern web
            application. Many of Pandell's other products had already undergone
            this upgrade process, but Pandell JV was left to the end because of
            the sheer size, complexity, and importance of the product.
          </P>
          <P>
            I was recruited to the team because I had made a big impact with my
            work over my first few months of employment. The team was looking
            for a developer to focus on upgrading the desktop application's
            clunky and dated interface to a new cross-browser web UI. One of the
            major challenges was building an interface that preserved all of the
            features that users liked about the old version, such as efficient
            keyboard navigation. Over time, I became involved in all the
            different tiers of the application and I tackled some of the most
            important features.
          </P>
        </>
      )
      break
    }
    case "verbose": {
      pandellEarlyYears = (
        <>
          <P>
            I was recruited to work on this project a few months after joining
            Pandell. The goal of the project was to rewrite Pandell's flagship
            accounting product,{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Pandell JV"
              href="https://www.pandelljv.com"
            >
              Pandell JV
            </a>
            , from a 15-year-old legacy desktop application to a modern web
            application. Many of Pandell's other products had already undergone
            this upgrade process, but Pandell JV was left to the end because of
            the sheer size, complexity, and importance of the product. It was a
            daunting, but also extremely exciting challenge.
          </P>
          <P>
            I was recruited to the team because I had made a big impact with my
            work over my first few months of employment. The team was looking
            for a developer to focus on upgrading the desktop application's
            clunky and dated interface to a new cross-browser web UI. One of the
            major challenges was building an interface that preserved all of the
            features that users liked about the old version, such as efficient
            keyboard navigation. At the time, our web applications were built
            with an in-house front-end framework based on{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="KnockoutJS"
              href="https://knockoutjs.com/"
            >
              Knockout
            </a>
            , so there was a lot of room for improvement.
          </P>
          <P>
            Initially, we were a small team of three developers. It was my first
            time being given a bit more responsibility and I worked hard to
            achieve our goals. This project was an important investment for the
            company so there was a big focus on iterating and settling on the
            best solution for the long-term success of the product. I became
            involved in all the different tiers of the application and I tackled
            some of the most important features, such as:
          </P>
          <TimeLineList>
            <li>
              Upgrading our web forms to improve data entry efficiency and
              speed, including autocomplete, inline validation, and contextual
              tooltip functionality
            </li>
            <li>Implementing financial transaction processing and reporting</li>
            <li>
              Designing and building a month-end checklist, allowing users to
              easily view the status of each task to be completed that month
            </li>
          </TimeLineList>
        </>
      )
      break
    }
  }

  let coreDevelopersTeam
  switch (verbosity) {
    case "terse": {
      coreDevelopersTeam = (
        <>
          <LightBoxText
            images={[
              {
                src: CoreDevelopers,
                thumbnail: CoreDevelopers,
              },
            ]}
          >
            <P>
              About a year after joining Pandell, I was{" "}
              <LightBoxLink>invited</LightBoxLink> to become a member of the
              Core Developers team. The Core Developers group is responsible for
              maintaining Pandell's internal development frameworks and tooling,
              and also leading new technical initiatives. The group consists of
              the ~15% most senior developers at the company.
            </P>
          </LightBoxText>
          <P>
            While working on Pandell JV, I regularly submitted bug fixes and
            enhancements to the "core", and I eventually established myself as a
            principal code reviewer in the core repositories. I was actively
            involved with the transition to using CommonJS modules in 2014,
            TypeScript in 2015, and React in 2018. Often this would involve
            trying out new technologies and tooling in my own projects prior to
            general company-wide release.
          </P>
          <P>
            Currently I'm one of the <Bold>top five</Bold> contributors to our
            front-end component library used by over 10 product teams and 40
            developers. One of my big areas of focus is improving consistency
            across our products. These initiatives have markedly improved
            developer productivity across all teams at Pandell.
          </P>
        </>
      )
      break
    }
    case "verbose": {
      coreDevelopersTeam = (
        <>
          <LightBoxText
            images={[
              {
                src: CoreDevelopers,
                thumbnail: CoreDevelopers,
              },
            ]}
          >
            <P>
              About a year after joining Pandell, I was{" "}
              <LightBoxLink>invited</LightBoxLink> to become a member of the
              Core Developers team.
            </P>
          </LightBoxText>
          <P>
            The Core Developers group is responsible for maintaining Pandell's
            internal development frameworks and tooling, and also leading new
            technical initiatives. The group consists of the ~15% most senior
            developers at the company, so at the time, it was both intimidating
            and inspiring to join the team. I appreciate that Pandell recognized
            my work ethic and rate of growth, and challenged me to become more
            involved in company-wide initiatives.
          </P>
          <P>
            We have an open source model for our shared code, where the lead
            developers of each product team dedicate some of their time to
            contribute to the "core". While working on Pandell JV, I regularly
            submitted bug fixes and enhancements, and I eventually established
            myself as a principal code reviewer in the core repositories. As
            part of that work, I have been widely recognized for my attention to
            detail and patience in answering questions and providing guidance. I
            was actively involved with the transition to using CommonJS modules
            in 2014, TypeScript in 2015, and React in 2018. Often this would
            involve trying out new technologies and tooling in my own projects
            prior to general company-wide release.
          </P>
          <P>
            Currently I'm one of the <Bold>top five</Bold> contributors to our
            front-end component library used by over 10 product teams and 40
            developers. I do this work largely on my own time outside of my
            primary responsibilities on a product team. One of my big areas of
            focus is improving consistency across our products. These
            initiatives have markedly improved developer productivity across all
            teams at Pandell.
          </P>
          <P>
            For example, one of my latest tasks was to create a standardized
            component for a panel that would be displayed when clicking on an
            item in a map. Previously, there were similar-but-slightly-different
            versions of this component in multiple products. I extracted a
            generic component to our shared component library and ensured that
            each team had a clear upgrade path for switching to the new
            component.
          </P>
        </>
      )
      break
    }
  }

  let pandellTeamLead
  switch (verbosity) {
    case "terse": {
      pandellTeamLead = (
        <>
          <P>
            My primary focus continued to be Pandell JV. The team size grew to
            eight developers and I transitioned into a technical lead role. I
            spent a greater part of my day on code reviews, mentorship,
            onboarding, and technical guidance. I also monitored and refined the
            core system architecture and tooling to maximize developer
            productivity and team momentum. This enabled the team to
            successfully achieve key deliverables as we progressed towards
            completing a minimum viable product.
          </P>
          <P>
            I also tackled some cross-cutting technical projects, such as
            implementing auditing and security functionality in the application.
          </P>
        </>
      )
      break
    }
    case "verbose": {
      pandellTeamLead = (
        <>
          <P>
            While I reserved some time every week for my Core Developer
            activities, my primary focus continued to be Pandell JV. The team
            size grew to eight developers and I transitioned quite naturally
            into a technical lead role. I shifted toward spending a greater part
            of my day on code reviews, mentorship, onboarding, and technical
            guidance. I also monitored and refined the core system architecture
            and tooling to maximize developer productivity and team momentum.
            This enabled the team to successfully achieve key deliverables as we
            progressed towards completing a minimum viable product.
          </P>
          <P>
            I also tackled some cross-cutting technical projects, such as
            implementing auditing and security functionality in the application.
          </P>
        </>
      )
      break
    }
  }

  let pandellSeniorDeveloper
  switch (verbosity) {
    case "terse": {
      pandellSeniorDeveloper = (
        <>
          <P>
            In 2018, I was offered a role to lead a newly-formed Executive Tools
            team. This was an internal R&amp;D team tasked with exploring new
            initiatives and product ideas geared towards higher-level company
            executives.
          </P>
          <P>
            The first project I led was a dashboard application to visualize
            data aggregated from multiple products across Pandell’s Oil &amp;
            Energy software suite. I was tasked with prototyping an application
            that would impressively combine data from multiple sources in
            dashboard widgets, data grids, and maps.
          </P>
          <P>
            A technical goal of the project was to build an application that had
            an interactive and dynamic user experience. I worked closely with
            Pandell's Creative Director to design new UI components to achieve
            these goals. I built various generic dashboard components that will
            serve as a foundation for all product dashboards going forward.
          </P>
          <P>
            One of the biggest challenges with this project was the tight
            timeline: I had about three months to design and build a
            fully-working prototype. I had to budget my time effectively and
            make smart tradeoffs between short-term and long-term solutions.
          </P>
          <LightBox
            images={[
              {
                src: DashboardWidgets,
                thumbnail: DashboardWidgetsThumbnail,
                caption: "Dashboard Widgets",
              },
              {
                src: DashboardGrid,
                thumbnail: DashboardGridThumbnail,
                caption: "Dashboard Grid",
              },
              {
                src: DashboardMap,
                thumbnail: DashboardMapThumbnail,
                caption: "Dashboard Map",
              },
            ]}
          />
          <P>
            The second project that I worked on was an application to identify
            data inconsistencies across multiple Pandell products and
            third-party systems. One of the interesting challenges of this
            project was implementing a scheduling mechanism to refresh data from
            source applications.
          </P>
          <LightBox
            images={[
              {
                src: SummaryChangesGrid,
                thumbnail: SummaryChangesGridThumbnail,
                caption:
                  "Grid of audited change events across Pandell applications",
              },
              {
                src: RefreshFrequency,
                thumbnail: RefreshFrequencyThumbnail,
                caption: "Refresh frequency settings",
              },
            ]}
          />
        </>
      )
      break
    }
    case "verbose": {
      pandellSeniorDeveloper = (
        <>
          <P>
            In 2018, I felt like I needed a new challenge. After discussing some
            options with my manager, I was offered a role to lead a newly-formed
            Executive Tools team. This was an internal R&amp;D team tasked with
            exploring new initiatives and product ideas geared towards
            higher-level company executives.
          </P>
          <P>
            The first project I led was a dashboard application to visualize
            data aggregated from multiple products across Pandell’s Oil &amp;
            Energy software suite. The product was an important strategic
            initiative for the company since it would entice clients to purchase
            our entire suite of products rather than just one or two products. I
            was tasked with prototyping an application that would impressively
            combine data from multiple sources in dashboard widgets, data grids,
            and maps.
          </P>
          <P>
            A technical goal of the project was to build an application that had
            an interactive and dynamic user experience. For example, we
            envisioned users having the ability to resize or rearrange widgets
            in the dashboard on the fly. I worked closely with Pandell's
            Creative Director to design new UI components to achieve these
            goals. I built various generic dashboard components that will serve
            as a foundation for all product dashboards going forward.
          </P>
          <P>
            One of the biggest challenges with this project was the tight
            timeline: I had about three months to design and build a
            fully-working prototype. I had to budget my time effectively,
            prioritize tasks, and make smart tradeoffs between short-term and
            long-term solutions. The prototype has received positive feedback
            from clients and I am currently building a full version of the
            product.
          </P>
          <LightBox
            images={[
              {
                src: DashboardWidgets,
                thumbnail: DashboardWidgetsThumbnail,
                caption: "Dashboard Widgets",
              },
              {
                src: DashboardGrid,
                thumbnail: DashboardGridThumbnail,
                caption: "Dashboard Grid",
              },
              {
                src: DashboardMap,
                thumbnail: DashboardMapThumbnail,
                caption: "Dashboard Map",
              },
            ]}
          />
          <P>
            The second project that I worked on was an application to identify
            data inconsistencies across multiple Pandell products and
            third-party systems. The system also allowed users to configure
            email notifications when changes were detected in source products.
            This product contributed to a broader company-wide initiative to
            improve the integration between our products.
          </P>
          <P>
            One of the interesting challenges of this project was implementing a
            scheduling mechanism to refresh data from source applications.
          </P>
          <LightBox
            images={[
              {
                src: SummaryChangesGrid,
                thumbnail: SummaryChangesGridThumbnail,
                caption:
                  "Grid of audited change events across Pandell applications",
              },
              {
                src: RefreshFrequency,
                thumbnail: RefreshFrequencyThumbnail,
                caption: "Refresh frequency settings",
              },
            ]}
          />
        </>
      )
      break
    }
  }

  let tech
  switch (verbosity) {
    case "terse": {
      tech = (
        <>
          <P>
            In terms of technology, I'm a well-rounded generalist. I've focused
            more on the front-end over the past couple of years (TypeScript,
            ES6, React), but I have also spent significant time working with
            server-side (C#, Java) and database (SQL) languages.
          </P>
          <P>
            While at Pandell we use .NET as a foundation for our server layer (
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Why we don’t hire .NET programmers (Expensify Blog)"
              href="https://blog.expensify.com/2011/03/25/ceo-friday-why-we-dont-hire-net-programmers/"
            >
              don't write me off!
            </a>
            ), we've implemented a substantial in-house framework as well. Above
            all, I strive to keep my code simple, straightforward, and highly
            readable.
          </P>
          <P>
            Outside of work, I occasionally experiment with creative coding
            projects on my own. A few of these are on{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Tim Phillips | Projects"
              href="https://tim-phillips.com/projects"
            >
              my website
            </a>
            .
          </P>
          <P>
            I know that Expensify uses PHP for their web API layer. While I
            don't have recent experience with PHP, I'm keen to pick it up!
          </P>
        </>
      )
      break
    }
    case "verbose": {
      tech = (
        <>
          <P>
            In terms of technology, I'm a well-rounded generalist. I have a deep
            understanding of each layer in a modern web application stack. I've
            focused more on the front-end over the past couple of years
            (TypeScript, ES6, React), but I have also spent significant time
            working with server-side (C#, Java) and database (SQL) languages.
          </P>
          <P>
            While at Pandell over the past seven years, I've worked on several
            large-scale web applications. While we use .NET as a foundation for
            our server layer (
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Why we don’t hire .NET programmers (Expensify Blog)"
              href="https://blog.expensify.com/2011/03/25/ceo-friday-why-we-dont-hire-net-programmers/"
            >
              don't write me off!
            </a>
            ), we've implemented a substantial in-house framework as well. In my
            own code, I generally gravitate towards a functional style that
            emphasises testable pure functions and intentional side effects.
            Above all, I strive to keep my code simple, straightforward, and
            highly readable.
          </P>
          <P>
            Outside of work, I occasionally experiment with creative coding
            projects on my own. A few of these are on{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Tim Phillips | Projects"
              href="https://tim-phillips.com/projects"
            >
              my website
            </a>
            .
          </P>
          <P>
            I know that Expensify uses PHP for their web API layer. While I
            don't have recent experience with PHP, I'm keen to pick it up!
          </P>
        </>
      )
      break
    }
  }

  let restOfYourLife
  switch (verbosity) {
    case "terse": {
      restOfYourLife = (
        <>
          <P>
            There are two aspects to this question: professional goals and
            personal goals. I'll try to tackle each one separately.
          </P>
          <Separator />
          <P>
            In my professional career, I want to spend my life building
            high-quality products that are <Italic>useful</Italic>. Having neat
            technology isn't enough by itself — I achieve the greatest
            satisfaction when I'm able to use technology to craft products that
            solve real problems and improve the life of their users.
          </P>
          <P>
            I want to be involved with projects that solve these problems in
            unique and innovative ways, and equally importantly, I want to have
            fun while I'm doing it. There's something extremely rewarding about
            developing software — starting with a problem, designing and
            implementing a solution, and working as a team to see it through to
            completion.
          </P>
          <P>
            While working at Pandell over the last seven years, I've worked very
            hard to advance from a new grad junior developer to a senior
            engineer working on the most important and impactful projects at the
            company. I've achieved a great deal of success at Pandell, but I'm
            looking to explore new opportunities. There are a few reasons for
            that, but I think a key sentiment is highlighted by{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Superstars are Built, not Bought (Expensify Blog)"
              href="https://blog.expensify.com/2016/06/24/superstars-are-built-not-bought/"
            >
              this Expensify blog post
            </a>
            :
          </P>
          <Quote>
            When an A player gets “stuck” in a local-maximum, and [they need] to
            actively search around to find a new global-maximum
          </Quote>
          <P>
            Expensify immediately stood out to me as an ideal fit for my career
            — the next "step up" for me in terms of opportunity, challenge, and
            impact. A couple of the key reasons for this include:
          </P>
          <Highlight>
            I want to work on larger-scale products that have a huge reach.
          </Highlight>
          <P>
            Tackling projects that operate on a bigger, global scale is a
            logical progression for my career. This is an area that I'm
            particularly interested in exploring and learning more about, so I'm
            aiming high by applying to the most widely used expense software in
            the world.
          </P>
          <Highlight>
            I want to surround myself with talented people with high standards.
          </Highlight>
          <P>
            I want to work with productive, high-performing teams. I've always
            pushed myself to work with developers who are more senior than
            myself — people who I can learn the most from and who will progress
            my own technical and leadership abilities. I've also enjoyed the
            process of mentoring junior developers. Regardless of seniority, I
            think it's incredibly important to work with team members who have a
            positive attitude and a team-focused mentality.
          </P>
          <Separator />
          <P>
            On a more practical level, my current plan is to pursue a technical
            track rather than a management track. Coding is my primary passion
            in life and my ideal position is one where I can spend the majority
            of my day fully immersed in that world. I'm at the stage of my
            career where I'm looking to make an impact beyond my own individual
            contributions. I want to work on cross-cutting initiatives to enable
            the best work from <Italic>all</Italic> developers. Ultimately, I
            want to be able to positively influence the success of the company
            as a whole.
          </P>
          <P>
            I'm also a highly product-focused individual and I love drilling
            into all the different dimensions of product development. UX design,
            in particular, is an interest of mine. I enjoy the challenge of
            modelling complex operations as intuitive, simple workflows. These
            skills are especially well-suited to Expensify's financial industry.
          </P>
          <P>
            I'll finish this section by giving a specific example of a career
            life goal of mine: to build a design system similar to{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Superstars are Built, not Bought (Expensify Blog)"
              href="https://polaris.shopify.com/"
            >
              Shopify Polaris
            </a>{" "}
            or{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Superstars are Built, not Bought (Expensify Blog)"
              href="https://airbnb.design/building-a-visual-language/"
            >
              Airbnb Design
            </a>
            . That would be a really fun and rewarding project!
          </P>
          <Separator />
          <P>
            For my personal life goals, I intentionally don't have goals to
            acquire <Italic>things</Italic> like a house or a car. I'm much more
            focused on living a life full of interesting experiences and
            culture. My partner and I decided to move from Calgary (Canada) to
            Edinburgh (UK) three years ago, and it has been an amazing
            experience. Another big dream of ours has been to move to a big
            multicultural metropolis like London or New York. Our goal is to
            live in many different cities throughout our lives and experience as
            many cultures as possible.
          </P>
          <P>
            I have a variety of hobbies outside of coding, so I try to maintain
            a reasonable work-life balance. My primary interest is{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Travel Map"
              href="http://map.tim-phillips.com/"
            >
              traveling
            </a>
            , which is, of course, another reason why I'm excited about working
            at Expensify. I'm especially keen to travel to nature-rich areas. I
            have a list of long-distance hikes that I want to complete in my
            life (such as the Pacific Crest Trail). Over the past couple years,
            I've hiked the Cami de Cavalls in Menorca, the Piz Kesch in the
            Swiss Alps, and the West Highland Way in Scotland.
          </P>
          <P></P>
          <P>
            Outside of travelling, I'm an avid photographer, guitar player and
            runner. However, my most important life goal is to support my
            partner, Alycia. She's pursuing (and succeeding!) in a career as a
            poet and Creative Writing educator. In 2018, she co-founded the{" "}
            <a
              rel="noopener noreferrer"
              title="Scottish BAME Writers Network"
              target="_blank"
              href="https://scottishbamewritersnetwork.org/"
            >
              Scottish BAME Writers Network
            </a>
            , a non-profit organization advocating for Black, Asian or minority
            ethnic writers from Scotland. That project has been a big focus for
            both of us over the last couple years. I'm really impressed to see
            that Expensify has charitable endeavors too!
          </P>
          <P>
            At the end of the day, I want to work hard, play hard, and live life
            to the fullest. And most importantly, I want to enable the success
            of the friends, family and coworkers in my life. From what I've
            seen, Expensify's unique work culture will allow me to excel both
            professionally and personally.
          </P>
        </>
      )
      break
    }
    case "verbose": {
      restOfYourLife = (
        <>
          <P>
            There are two aspects to this question: professional goals and
            personal goals. I'll try to tackle each one separately.
          </P>
          <Separator />
          <P>
            In my professional career, I want to spend my life building
            high-quality products that are <Italic>useful</Italic>. Having neat
            technology isn't enough by itself — I achieve the greatest
            satisfaction when I'm able to use technology to craft products that
            solve real problems and improve the life of their users.
          </P>
          <P>
            I want to be involved with projects that solve these problems in
            unique and innovative ways, and equally importantly, I want to have
            fun while I'm doing it. There's something extremely rewarding about
            developing software — starting with a problem, designing and
            implementing a solution, and working as a team to see it through to
            completion. It is this sense of accomplishment that keeps me excited
            about software engineering and pushes me to continually seek out new
            challenges and opportunities. It feels even <Italic>more</Italic>{" "}
            rewarding when these goals are accomplished while working alongside
            a talented team of coworkers who share your vision and ambitions.
          </P>
          <P>
            While working at Pandell over the last seven years, I've worked very
            hard to advance from a new grad junior developer to a senior
            engineer working on the most important and impactful projects at the
            company. This progression has been possible because, much like at
            Expensify, the leaders at Pandell see value in giving their
            employees room to grow and take ownership of their work. I've
            achieved a great deal of success at Pandell, but I'm looking to
            explore new opportunities. There are a few reasons for that, but I
            think a key sentiment is highlighted by{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Superstars are Built, not Bought (Expensify Blog)"
              href="https://blog.expensify.com/2016/06/24/superstars-are-built-not-bought/"
            >
              this Expensify blog post
            </a>
            :
          </P>
          <Quote>
            When an A player gets “stuck” in a local-maximum, and [they need] to
            actively search around to find a new global-maximum
          </Quote>
          <P>
            I've been researching UK tech companies for a few months and
            Expensify immediately stood out to me as an ideal fit for my career
            — the next "step up" for me in terms of opportunity, challenge, and
            impact. A couple of the key reasons why I think Expensify is an
            excellent opportunity for me to include:
          </P>
          <Highlight>
            I want to work on larger-scale products that have a huge reach.
          </Highlight>
          <P>
            The accounting software that I work on at Pandell is geared
            specifically towards Energy companies in Canada and the US. As a
            result, there is a much smaller market for our products. There are
            many interesting problems to solve in our industry, but{" "}
            <Italic>scale</Italic> has not always been a major focus. We deal
            with <Italic>thousands</Italic> of users instead of{" "}
            <Italic>millions</Italic>. Tackling projects that operate on a
            bigger, global scale is a logical progression for my career. This is
            an area that I'm particularly interested in exploring and learning
            more about, so I'm aiming high by applying to the most widely used
            expense software in the world.
          </P>
          <Highlight>
            I want to surround myself with talented people with high standards.
          </Highlight>
          <P>
            When I was looking into Expensify, something that came up regularly
            in blogs and interviews is the hiring philosophy - the drive to hire
            talented, hard-working individuals, and then support them in every
            way possible so that they achieve their potential. We have a similar
            hiring strategy at Pandell, and I've seen how effective this
            approach can be at building cohesive teams.
          </P>
          <P>
            In my next job, I want to continue to work with productive,
            high-performing teams. I've always pushed myself to work with
            developers who are more senior than myself — people who I can learn
            the most from and who will progress my own technical and leadership
            abilities. I've also enjoyed the process of mentoring junior
            developers. Regardless of seniority, I think it's incredibly
            important to work with team members who have a positive attitude and
            a team-focused mentality. This is a key requirement for me, and
            Expensify definitely appears to exceed the norm in this regard.
          </P>
          <Separator />
          <P>
            On a more practical level, my current plan is to pursue a technical
            track rather than a management track. Coding is my primary passion
            in life and my ideal position is one where I can spend the majority
            of my day fully immersed in that world. I'm at the stage of my
            career where I'm looking to make an impact beyond my own individual
            contributions. I want to work on cross-cutting initiatives to enable
            the best work from <Italic>all</Italic> developers. Ultimately, I
            want to be able to positively influence the success of the company
            as a whole.
          </P>
          <P>
            I'm also a highly product-focused individual and I love drilling
            into all the different dimensions of product development — from
            hashing out requirements with domain experts, to designing intuitive
            user interfaces, to (last but not least) architecting scalable and
            robust systems to bring it all together into workable products. UX
            design, in particular, is an interest of mine. I enjoy the challenge
            of modelling complex operations as intuitive, simple workflows. As
            I've already experienced while working on accounting products at
            Pandell, these skills are especially well-suited to Expensify's
            financial industry.
          </P>
          <P>
            I'll finish this section by giving a specific example of a career
            life goal of mine: to build a design system similar to{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Superstars are Built, not Bought (Expensify Blog)"
              href="https://polaris.shopify.com/"
            >
              Shopify Polaris
            </a>{" "}
            or{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Superstars are Built, not Bought (Expensify Blog)"
              href="https://airbnb.design/building-a-visual-language/"
            >
              Airbnb Design
            </a>
            . That would be a really fun and rewarding project!
          </P>
          <Separator />
          <P>
            For my personal life goals, I intentionally don't have goals to
            acquire <Italic>things</Italic> like a house or a car. I'm much more
            focused on living a life full of interesting experiences and
            culture. My partner and I decided to move from Calgary (Canada) to
            Edinburgh (UK) three years ago, and it has been an amazing
            experience. Another big dream of ours has been to move to a big
            multicultural metropolis like London or New York. If this
            application is successful then we'll relocate to London and check
            that off our list! Our goal is to live in many different cities
            throughout our lives and experience as many cultures as possible.
          </P>
          <P>
            I have a variety of hobbies outside of coding, so I try to maintain
            a reasonable work-life balance. My primary interest is{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              title="Travel Map"
              href="http://map.tim-phillips.com/"
            >
              traveling
            </a>
            , which is, of course, another reason why I'm excited about working
            at Expensify. I'm especially keen to travel to nature-rich areas
            where I can hike and enjoy the great outdoors. I have a list of
            long-distance hikes that I want to complete in my life (such as the
            Pacific Crest Trail). Over the past couple years, I've hiked the
            Cami de Cavalls in Menorca, the Piz Kesch in the Swiss Alps, and the
            West Highland Way in Scotland. In 2018, a friend and I hiked over
            100km to a remote area of Banff National Park in Canada to spot
            bison that had been reintroduced into the wild for the first time in
            a century.
          </P>
          <P>
            Outside of travelling, I'm an avid photographer, guitar player and
            runner. However, my most important life goal is to support my
            partner, Alycia. She's pursuing (and succeeding!) in a career as a
            poet and Creative Writing educator. In 2018, she co-founded the{" "}
            <a
              rel="noopener noreferrer"
              title="Scottish BAME Writers Network"
              target="_blank"
              href="https://scottishbamewritersnetwork.org/"
            >
              Scottish BAME Writers Network
            </a>
            , a non-profit organization advocating for Black, Asian or minority
            ethnic writers from Scotland. That project has been a big focus for
            both of us over the last couple years. I'm really impressed to see
            that Expensify has charitable endeavors too!
          </P>
          <P>
            At the end of the day, I want to work hard, play hard, and live life
            to the fullest. And most importantly, I want to enable the success
            of the friends, family and coworkers in my life. From what I've
            seen, Expensify's unique work culture will allow me to excel both
            professionally and personally.
          </P>
        </>
      )
      break
    }
  }

  return (
    <QuestionList>
      <Question>
        <QuestionTitle>What's the URL of your website?</QuestionTitle>
        {website}
      </Question>
      <Question>
        <QuestionTitle>What's your coding history?</QuestionTitle>
        {codingHistoryIntro}
        <TimeLine>
          <TimeLineEntry>
            <TimeLineSideBar>
              <TimeLineIcon />
            </TimeLineSideBar>
            <TimeLineContent>
              <TimeLineTitle>Childhood</TimeLineTitle>
              <LightBoxText
                images={[
                  {
                    src: GameScreenshot1,
                    thumbnail: GameScreenshot1,
                  },
                  {
                    src: GameScreenshot2,
                    thumbnail: GameScreenshot2,
                  },
                ]}
              >
                {childhood}
              </LightBoxText>
            </TimeLineContent>
          </TimeLineEntry>
          <TimeLineEntry>
            <TimeLineSideBar>
              <TimeLineIcon />
            </TimeLineSideBar>
            <TimeLineContent>
              <TimeLineTitle>University</TimeLineTitle>
              {university}
            </TimeLineContent>
          </TimeLineEntry>
          <TimeLineEntry>
            <TimeLineSideBar>
              <TimeLineIcon />
            </TimeLineSideBar>
            <TimeLineContent>
              <TimeLineTitle>Haemonetics Internship</TimeLineTitle>
              {hamonetics}
            </TimeLineContent>
          </TimeLineEntry>
          <TimeLineEntry>
            <TimeLineSideBar>
              <TimeLineIcon />
            </TimeLineSideBar>
            <TimeLineContent>
              <TimeLineTitle>Pandell</TimeLineTitle>
              {pandellIntro}
              <ContributionStats />
            </TimeLineContent>
          </TimeLineEntry>
          <TimeLineEntry>
            <TimeLineSideBar>
              <TimeLineIcon />
            </TimeLineSideBar>
            <TimeLineContent>
              <TimeLineTitle>Pandell JV — The Early Years</TimeLineTitle>
              {pandellEarlyYears}
              <LightBox
                images={[
                  {
                    src: VoucherDataEntry,
                    thumbnail: VoucherDataEntryThumbnail,
                    caption: "Transaction Data Entry",
                  },
                  {
                    src: CapitalOverhead,
                    thumbnail: CapitalOverheadThumbnail,
                    caption: "Capital Overhead Calculations",
                  },
                  {
                    src: MonthEndChecklist,
                    thumbnail: MonthEndChecklistThumbnail,
                    caption: "Month-End Checklist",
                  },
                ]}
              />
            </TimeLineContent>
          </TimeLineEntry>
          <TimeLineEntry>
            <TimeLineSideBar>
              <TimeLineIcon />
            </TimeLineSideBar>
            <TimeLineContent>
              <TimeLineTitle>Pandell — Core Developer Team</TimeLineTitle>
              {coreDevelopersTeam}
              <LightBox
                images={[
                  {
                    src: InspectorPanel,
                    thumbnail: InspectorPanelThumbnail,
                    caption: "InspectorPanel component in our Storybook",
                  },
                  {
                    src: InspectorPanelUsage,
                    thumbnail: InspectorPanelUsageThumbnail,
                    caption: "InspectorPanel usage in an application",
                  },
                ]}
              />
            </TimeLineContent>
          </TimeLineEntry>
          <TimeLineEntry>
            <TimeLineSideBar>
              <TimeLineIcon />
            </TimeLineSideBar>
            <TimeLineContent>
              <TimeLineTitle>Pandell — Team Lead </TimeLineTitle>
              {pandellTeamLead}
            </TimeLineContent>
          </TimeLineEntry>
          <TimeLineEntry>
            <TimeLineSideBar>
              <TimeLineIcon />
            </TimeLineSideBar>
            <TimeLineContent>
              <TimeLineTitle>Pandell — Senior Developer</TimeLineTitle>
              {pandellSeniorDeveloper}
            </TimeLineContent>
          </TimeLineEntry>
        </TimeLine>
        <Highlight>A Note On Tech</Highlight>
        {tech}
      </Question>
      <Question>
        <QuestionTitle>
          What do you want to do with the rest of your life, and how is
          Expensify a step toward your long-term goals?
        </QuestionTitle>
        {restOfYourLife}
      </Question>
      <Question>
        <QuestionTitle>How did you hear about us?</QuestionTitle>
        <P>
          I saw a post advertising the position on{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            title="Hacker News"
            href="https://news.ycombinator.com/item?id=22750206"
          >
            Hacker News
          </a>
          .
        </P>
      </Question>
    </QuestionList>
  )
}
