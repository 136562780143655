import { css } from "styled-components"

// https://reactcommunity.org/react-transition-group/css-transition

const TransitionStyles = css`
  .fade-enter {
    opacity: 0.01;
    transition: opacity 1000ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .fade-enter-active {
    opacity: 1;
    transition: opacity 1000ms cubic-bezier(0.645, 0.045, 0.355, 1);
  }
`

export default TransitionStyles
